<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">{{ $t("paymentModal.payments") }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`orderChange.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row row-tabs">
            <div
              class="col-auto p-0 m-0"
              v-for="l in activeLanguages"
              :key="l.code"
            >
              <button
                class="btn btn-tab"
                :class="{ active: l.code === selectedLanguage }"
                v-on:click="this.selectedLanguage = l.code"
              >
                <span class="semibold-14">{{ l.name }}</span>
              </button>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-4">
              <button
                v-for="payment in payments"
                :key="payment.id"
                class="btn w-100 d-block text-start"
                v-on:click="loadeditPayment(payment)"
                :class="{
                  'btn-primary': editPayment?.id === payment.id,
                  'btn-simple': editPayment?.id !== payment.id,
                }"
              >
                {{ payment.name }}
              </button>
            </div>
            <div class="col-8 row" v-if="editPayment !== null">
              <div class="col-12 mb-3 whitebg-input">
                <label class="form-label bold-12 text-gray">
                  {{ $t("paymentModal.paymentMethodName") }}
                </label>
                <input
                  class="form-control"
                  v-model="editPayment.name[selectedLanguage]"
                />
              </div>
              <div class="col-12 mb-3 whitebg-input">
                <label class="form-label bold-12 text-gray">
                  {{ $t("paymentModal.paymentMethodDescription") }}
                </label>
                <WYSIWYG v-model="editPayment.description[selectedLanguage]" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            v-on:click="savePayment"
          >
            {{ $t("orderChange.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WYSIWYG from "../inputs/WYSIWYG";
import { useStore } from "vuex";
import http from "@/modules/http";
const bootstrap = require("bootstrap");

export default {
  name: "PaymentsModal",
  components: { WYSIWYG },
  emits: ["savePayment"],
  computed: {
    activeLanguages() {
      const store = useStore();
      return store.state.languages;
    },
  },
  data() {
    return {
      payments: [],
      selectedLanguage: "en",
      editPayment: null,
    };
  },
  methods: {
    load() {
      http.fetch("/payments/methods/").then((data) => {
        this.payments = data;
        this.loadeditPayment(this.payments[0]);
      });
    },
    savePayment() {
      http.fetch(
        "/payments/methods/" + this.editPayment.id + "/translate",
        this.editPayment
      );
      this.$emit("savePayment", this.editPayment);
    },
    hideModal() {
      this.myModal.hide();
    },
    loadeditPayment(payment) {
      http.fetch("/payments/methods/" + payment.id).then((data) => {
        this.editPayment = data;
      });
    },
    showModal() {
      this.myModal = new bootstrap.Modal(this.$refs.modal, {
        backdrop: "static",
      });
      this.myModal.show();
      this.load();
    },
  },
};
</script>
